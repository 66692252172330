.Sporsmal {
  margin-bottom: 2rem;

  .u-h2 {
    color: var(--darkgrey);
  }

  &-alternativer {
    font-weight: 700;
    letter-spacing: 0.33px;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &-input {
    input {
      background: var(--white);
      height: 2.5rem;
    }

    i {
      bottom: 0.875rem;
    }
  }

  &-list {
    display: flex;
    width: 100%;
    margin: 0;

    &-item {
      flex: 1;
    }
  }

  &-dato {
    label {
      display: inline-block;
    }

    input {
      background: var(--white);
      height: 2.5rem;
    }

    i {
      bottom: 0.875rem;
    }
  }

  &-fylkeKommune {
    width: 20rem;

    [role="combobox"] {
      background: var(--white);
      height: 2.5rem;
      line-height: 2.5rem;
      padding-left: 0.5rem;
      overflow: hidden;
    }

    [role="combobox"] i {
      top: 0.75rem;
      right: 0.5rem;
    }
  }

  &-button-vedlegg,
  &-button {
    margin-top: 1rem;
    border-color: transparent;
    background: var(--white);

    &[aria-pressed="true"] {
      background: var(--secondary);

      &:hover {
        color: var(--darkgrey);
      }
    }

    &:before {
      bottom: -0.125rem;
      content: "";
      height: 0.125rem;
      left: -0.125rem;
      position: absolute;
      right: -0.125rem;
      background-color: var(--secondary);
    }

    &[disabled]:before,
    &[aria-disabled="true"]:before {
      background-color: var(--grey);
    }

    &--error {
      border-color: var(--alertred);
      border-style: dashed;
    }

    &--error:before {
      content: none;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  &--noMargin {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  &-heading {
    background: var(--accent);
    color: var(--accent-text);
    padding: 1rem 0.5rem;

    .fr-view:not(.fr-element) {
      color: var(--accent-text) !important;
    }

    &-title {
      font-weight: 700;
    }

    &-choose {
      margin-top: 3rem !important;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &-body {
    background: var(--offwhite);
    padding: 0.5rem 0.5rem;

    &:first-of-type {
      padding-top: 2rem;
    }

    &:last-of-type {
      padding-bottom: 2rem;
    }

    &--bold {
      font-weight: 700;
    }

    &--italic {
      font-style: italic;
    }

    .fr-box {
      background: var(--white);
    }
  }

  &-edit {
    position: relative;
    transition: all linear 0.1s;

    &-alternativer {
      display: flex;
      gap: 2rem;
      flex-direction: column;
    }

    &--preview {
      font-weight: 500;
      padding-top: 0.5rem;
      color: var(--darkgrey);
    }

    &--active {
      margin: 0 -2rem;
      padding: 1rem 3rem 2rem 3rem !important;
      border-top: 0.125rem solid var(--secondary);
      box-shadow: 0 1.5625rem 2.5rem -1.25rem var(--darkgrey50);
      background: var(--white) !important;
      z-index: 1;
    }

    &-maxCount {
      &-input {
        width: 12rem;
        display: inline-block;
      }

      &-button {
        margin-left: 1rem;
        vertical-align: bottom;
      }
    }

    &-wrapper {
      display: flex;
    }

    &-divider {
      height: 0.125rem;
      width: 100%;
      background: var(--bordergrey);
      margin: 2rem 0;
      border: 0;
    }

    &-title {
      flex: 1 1 auto;
      margin-right: 1rem;
      margin-top: -1.5rem;

      &-textarea {
        background: var(--white);
        font-size: 1rem !important;
        font-weight: 700 !important;
        min-height: 4.5rem;
        resize: vertical;
      }
    }

    &-buttons {
      margin-top: 2rem;

      &-dropdown {
        float: right;

        div ul li button {
          max-width: 100%;
        }
      }
    }

    &-text {
      margin: 1rem 0 2rem;
    }

    &-reorder {
      flex: 0 0 auto;

      button {
        padding: 0 0.75rem;
      }
    }

    &-new {
      position: relative;
      cursor: pointer;
      height: 5rem;
      transition: all linear 0.1s;

      &:after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 0;
        top: 50%;
        transform: translateY(-50%);
        background: var(--accent);
        transition: all linear 0.1s;
      }

      &:hover&:after {
        height: 1rem;
      }
    }
  }

  &-edit &-body {
    background: transparent;
    padding: 0;
  }
}

@media @medium {
  .Sporsmal {
    &-heading {
      padding: 2rem;
    }

    &-body {
      padding: 1rem 2rem;
    }

    &-heltall {
      display: block;
      max-width: 10rem;
    }

    &-korttekst {
      display: block;
      max-width: 22rem;
      min-width: 22rem;
      transition: all linear 0.1s;

      &--wide {
        max-width: 100%;
      }
    }
  }
}

@media only screen and (width >= 777px) {
  .Sporsmal {
    &-button {
      display: inline-block;
      margin-top: 0;
    }
  }
}
