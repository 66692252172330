.AutoSaveLoader {
  position: sticky;
  top: -2.5rem;
  right: 0;
  left: 0;
  color: var(--white);
  background: var(--black);
  text-align: right;
  padding: 0.5rem 1rem;
  transition: all linear 0.2s;
  z-index: 100;

  &--visible {
    top: 0;
  }

  &-spinner {
    margin: 0 0 0 0.5rem;
    display: inline-block;
    height: 0.625rem;
    text-align: center;

    &-rect {
      background-color: var(--white);
      height: 100%;
      width: 0.125rem;
      margin: 0 0.0625rem;
      display: inline-block;
      animation: animation-strech 1.2s infinite ease-in-out;

      &-1 {
        animation-delay: 0;
      }

      &-2 {
        animation-delay: -1.1s;
      }

      &-3 {
        animation-delay: -1s;
      }

      &-4 {
        animation-delay: -0.9s;
      }

      &-5 {
        animation-delay: -0.8s;
      }
    }
  }
}

@media @extra-large {
  .AutoSaveLoader {
    background: none;
  }
}

@keyframes animation-strech {
  0%,
  40%,
  100% {
    transform: scaleY(1);
  }

  20% {
    transform: scaleY(1.6);
  }
}
