.Header {
  background: var(--white);
  padding: 0.5rem;

  &-logo {
    margin: 1rem 0 3rem 0;
    background-repeat: no-repeat;
    background-size: auto 3rem;
    height: 3rem;
  }

  &-title {
    display: block;
    position: relative;
    font-size: 0.875rem;
  }

  &-content {
    position: relative;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 2rem;
    transform: all linear 0.1s;

    &-edit {
      position: absolute;
      top: -1rem;
      left: -1rem;
      padding: 1rem;
      box-shadow: 0 1.5625rem 2.5rem -1.25rem var(--darkgrey50);
      background: var(--white);
      min-width: 16rem;
      width: 100%;
      z-index: 11;
      transform: all linear 0.1s;
    }
  }

  &-buttons {
    text-align: right;
  }

  &-button {
    background: var(--white);
    margin-top: 0.5rem;
  }

  &-edit {
    display: inline-block;

    &-buttons {
      float: right;
    }
  }

  &--innsendt {
    color: var(--white);
    background: var(--activeblue);
    margin: -0.5rem -0.5rem 0 -0.5rem;
    padding: 0 1rem;
  }
}

@media @medium {
  .Header {
    padding: 2rem;

    &-button {
      margin-top: 0;
    }

    &--innsendt {
      padding: 0 2.5rem;
    }
  }
}
