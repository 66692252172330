.Obligatorisk {
  display: block;
  width: calc(100% - 24px);
  position: relative;
  transition: all linear 0.1s;

  &--empty {
    width: auto;
  }

  &--block {
    display: block !important;
  }

  &-icon {
    position: absolute;
    right: -1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &--withLabel &-icon {
    top: 70%;
  }
}

@media @medium {
  .Obligatorisk {
    width: auto;
    display: inline-block;

    &--empty {
      width: auto;
    }

    &--block {
      width: calc(100% - 48px);
    }

    &-icon {
      right: -3rem;
    }
  }
}
