@import "@utdanningsdirektoratet/styles/constants";
@import "@utdanningsdirektoratet/styles/imports";
@import "@utdanningsdirektoratet/styles/mixins";
@import "@utdanningsdirektoratet/grid/Grid.module.less";
@import "../../../../Hoering.Web.Shared/client/static/less/base";
@import "./components/tutorialoverlay";
@import "./components/footer";
@import "./components/autosaveloader";
@import "./components/sendInnPopin";

html {
  height: 100%;
  overflow-y: scroll;
}

body {
  position: relative;
  padding-bottom: 11.25rem;
  min-height: 100%;
  margin: 0;
}

@media @large {
  body {
    padding-bottom: 10rem;
  }
}

html,
body {
  background: var(--background-external);
  position: relative;
  color: var(--darkgrey);
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  box-sizing: border-box;
  vertical-align: baseline;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

strong,
b {
  .strong();
}

em,
i {
  .italic();
}

a {
  position: relative;
  color: var(--activeblue);
  outline: none;

  &:hover,
  &:focus {
    color: var(--darkgrey);
  }
}

ul,
ol {
  margin-bottom: 2rem;
  padding-left: 1.1rem;

  li {
    margin-bottom: 0.5rem;
  }

  ul,
  ol {
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
}

p {
  margin: 0;
  margin-bottom: 2rem;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.loader-60devs[data-state="running"]::before {
  display: none;
}

::selection {
  background: var(--primary50);
}

:not(main):target {
  background: var(--primary50);
  transition: all linear 0.2s;
}

.fr-box .fr-counter {
  float: left;
}

.Footer footer {
  position: absolute;
}

@media only screen and (width >= 1750px) {
  .Grid {
    &--offset {
      position: relative;
      left: -9.5rem;
    }
  }
}
